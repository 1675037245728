<template lang="pug">
.signup-button
  LButton(@click="$router.push('/signup')") {{ $t('sign up') }}

</template>

<script>
// TODO i18n
import LButton from '@/components/ui/LButton'

export default {
  name: 'SignupButton',
  props: {
    msg: String
  },
  data () {
    return {
      auth: false
    }
  },
  components: {
    LButton
  }
}
</script>

<
<style lang="scss">
.profile {
  display: flex;
}
</style>
