<template lang="pug">
.profile
  template(v-if="HAS_AUTH")
    .profile__user(@click="goToApp()")
      .profile__user-name {{ getUserName }}
      LAvatar(size="small" :url="getUrl")

  template(v-else)
    LButton(
      @click="$router.push('/signin')"
      theme="transparent"
      size="small"
    ) {{ $t('sign in') }}
    LButton(
      @click="$router.push('/signup')"
      size="small"
    ) {{ $t('sign up') }}

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  props: {
    msg: String
  },
  data () {
    return {
      auth: false
    }
  },
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar'),
  },
  computed: {
    ...mapGetters('user', ['HAS_AUTH', 'USER_DATA', 'SETTINGS']),
    getUserName () {
      if (this.SETTINGS !== null) {
        return this.SETTINGS.name
      }
    },
    getUrl () {
      if (this.SETTINGS !== null) {
        return this.SETTINGS.photo.preview
      }
    }
  },
  methods: {
    goToApp: () => { window.location.href = '/app/' },
  }
}
</script>


<style lang="scss">
.profile {
  display: flex;
  flex-shrink: 0;
  &__user {
    display: flex;
    align-items: center;
    &-name {
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        color: #2F3A9E;
        text-decoration: underline;
      }
    }
  }
}
</style>
