import Vue from 'vue'
import Landing from './Landing.vue'

import LUserAuthProfile from './components/global/LUserAuthProfile.vue'
import SignupButton from './components/global/SignupButton.vue'
import LDropDownLanguage from './components/global/LDropDownLanguage.vue'

import i18n from './i18n'
import router from './router/landing'
import PortalVue from 'portal-vue'
import axios from './axios.js'
import store from './store'
import VueAxios from 'vue-axios'

import VueCroppie from 'vue-croppie';

import focus from './directives/focus.js'


Vue.directive('focus', focus)

Vue.use(VueCroppie)

Vue.use(PortalVue)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(Landing)
}).$mount('#auth')

new Vue({
  router,
  store,
  i18n,
  render: h => h(LUserAuthProfile)
}).$mount('#profile')

new Vue({
  router,
  store,
  i18n,
  render: h => h(LDropDownLanguage)
}).$mount('#language')

/*
  info for landing

  landing must have:
  - #app before </body>
  - #profile in header for user profile and signin/signup
  - '.signup-button' for auto replace vue button
*/
document.addEventListener('DOMContentLoaded', () => {
  const signupButtons = document.getElementsByClassName("signup-button")

  // can send props
  for (let el of signupButtons) {
    new Vue({
      router,
      i18n,
      render: h => h(SignupButton),
      el
    })
  }

});

