import Vue from 'vue'
import VueRouter from 'vue-router'
// stub dublicate route error

import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)

import userStore from '@/store/user'

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
};
// end stub
Vue.use(VueRouter)

const routes = [

  /*
   * AUTH ROUTES
  */
  {
    path: '/signin',
    name: 'signin',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Signin.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Signup.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/signup/additional',
    name: 'signup',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/SignupAdditional.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/signup/links',
    name: 'signup',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/SignupLinks.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ConfirmCode.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/confirm/code/:email',
    name: 'confirmCode',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ConfirmCode.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/confirm/:email',
    name: 'confirmCode',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ConfirmCode.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/termsofuse',
    name: 'termsOfUse',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/TermsOfUse.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/confirm/code',
    name: 'confirmCode',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ConfirmCode.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Recovery.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/recovery/code',
    name: 'recovery-code',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/RecoveryCode.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  },
  {
    path: '/recovery/code/:email',
    name: 'recovery-code',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/RecoveryCode.vue'),
    meta: {
      isLanding: true,
      auth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




export default router
