<template lang="pug">
#app
  //- transition(name="fade")
  routerView

</template>

<script>
import { mapActions } from 'vuex'

export default {
  mounted () {
    this.loadAuth()
  },
  methods: {
    ...mapActions('user', ['loadAuth'])
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  pointer-events: none; // for interception click without wait animation end
}
</style>
